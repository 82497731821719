import React from "react"
import "./style.scss"
import { Container, Row, Col, Nav } from "react-bootstrap"
import LogoFSRI from "../../assets/brand/FSRI_UL_FULL_COLOR_LOGO_white.svg"

export default () => (
  <footer>
    <Container className="colophon" fluid>
      <Container className={"section-padding"}>
        <Row>
          <Col xs={12} sm={7}>
            <Col lg={8} className="info no-padding">
              <p>
                The Fire Safety Research Institute (FSRI), part of UL Research
                Institutes, strives to advance fire safety knowledge and
                strategies in order to create safer environments. Using advanced
                fire science, rigorous research, extensive outreach and
                education in collaboration with an international network of
                partners, the organization imparts stakeholders with knowledge,
                tools, and resources that enable them to make better, more fire
                safe decisions that ultimately save lives and property. To learn
                more, visit fsri.org. Follow Fire Safety Research Institute on
                Twitter, Instagram, Facebook, and LinkedIn.
              </p>
            </Col>
            <Col></Col>
          </Col>
          <Col>
            <Row>
              <Col xs={12} md={9} className={"align-items-start"}>
                <Nav activeKey="#" className={"flex-column section-padding-sm"}>
                  <Nav.Link
                    href="http://www.ul.com/customer-resources/online-policies/privacy-policy/"
                    target={"_blank"}
                    rel={"noopener norefferer"}
                  >
                    Privacy Policy
                  </Nav.Link>
                  <Nav.Link
                    href="http://www.ul.com/customer-resources/online-policies/terms-of-use/"
                    target={"_blank"}
                    rel={"noopener norefferer"}
                  >
                    Terms of Use
                  </Nav.Link>
                  <div id="teconsent" className="nav-link"></div>
                </Nav>
              </Col>
              <Col xs={12} md={3}>
                <Nav
                  className={
                    "align-items-sm-start justify-content-md-start section-padding-sm"
                  }
                >
                  <a
                    href="https://ul.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer-logo"
                      src={require("../../assets/images/ulresearchinst_logo_horz_wht_rgb .png")}
                      width={"100vw"}
                      height={"auto"}
                    />
                  </a>
                  <a
                    href="//fsri.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LogoFSRI className="icon" />
                  </a>
                </Nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
    <Container className="copyright" fluid>
      <Container className={"no-padding"}>
        <Row>
          <Col>
            <p>
              &copy; {new Date().getFullYear()} Underwriters Laboratories Inc. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  </footer>
)
